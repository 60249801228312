import React from 'react';
import TextField from '@material-ui/core/TextField'
import { List, ListItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'clsx'
import { Auth } from 'aws-amplify'
const useStyles = makeStyles(() => {
    return {
        loginContainer: {
            padding: '24px'
        },
        loginText: {
            fontSize: '32px',

        },
        textField: {
            backgroundColor: 'white',
            borderRadius: '4px',
            margin: '8px'
        }
    }
})

function useReset(u: any): [(confirm: string) => Promise<void>, any, any, boolean] {
    const [error, setError] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [user, setUser]= React.useState()
    return [async  (confirm: string) => {
        try {
            setLoading(true)  
            await Auth.completeNewPassword(u, confirm, {preferred_username: u.username});
            setUser(u)
            setLoading(false)
            setError(undefined)
        } catch (error) {
            setLoading(false)
            console.log('error signing in', error);
            setError(error)

        }
    }, user, error, loading]
}
const validate = (password: string, confirm: string) => {
    if(!password || !confirm) return false
    if(password !== confirm) return false
    if(password.length < 8) return false;
    if(!(/[a-z]/g).test(password)) return false
    if(!(/[0-9]/g).test(password)) return false
    if(!(/[=+-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/g).test(password)) return false
    return true
}

function ResetPassword({ setReset, user: u}: { user: any, setReset: (auth: boolean | null) => void}) {
    const classes = useStyles()
    const [confirm, setConfirm] = React.useState('')
    const [password, setPassword] = React.useState('')

    const [reset, user, error, loading] = useReset(u)

    const handleConfirm = React.useCallback((e) => {

        setConfirm(e.target.value)
    }, [setConfirm])
    const handlePassword = React.useCallback((e) => {
        setPassword(e.target.value)
    }, [setPassword])

    const handleSubmit = React.useCallback(async (e) => {
        e.preventDefault()
        await reset(confirm)
    }, [confirm, reset])

    React.useEffect(() => {
        if(user && !error) {
            setReset(false)
        }

    }, [setReset, error, user])

    return (
    <form onSubmit={handleSubmit}>
        <div className={classes.loginContainer}>
            <code className={classes.loginText}>Change Password</code>
        </div>
        {error && <div><code style={{ color: 'red' }}>Something went wrong!</code>
        <code style={{ color: 'red', fontSize: "16px", display: 'block', marginBottom: '16px' }}>Please refresh and try logging in again.</code></div>}
        <TextField 
            className={cx(classes.textField)} 
            variant="outlined" 
            placeholder="New Password"
            onChange={handlePassword}
            type="password"
            />
        <br />
        <TextField 
            className={cx(classes.textField)} 
            variant="outlined" 
            placeholder="Confirm Password" 
            type="password" 
            onChange={handleConfirm} />
        <br/>
        <br />
        <Typography>Requirements:</Typography>
        <List style={{fontSize: '16px', paddingLeft: '32px'}}>
            <ListItem style={{ padding: '0px 0px 0px 16px'}}>* 8 Characters long</ListItem>
            <ListItem style={{ padding: '0px 0px 0px 16px'}}>* One lowercase</ListItem>
            <ListItem style={{ padding: '0px 0px 0px 16px'}}>* One digit</ListItem>
            <ListItem style={{ padding: '0px 0px 0px 16px'}}>* One special character</ListItem>
        </List>
        <br />
        
        <Button variant="contained" size="large" type="submit" disabled={loading || !validate(password, confirm)}>Change Password</Button>
    </form>)
}

export default ResetPassword