import React from 'react';
import TextLoop from "react-text-loop";
import Login from './Login'
import ResetPassword from './ResetPassword'
import StatusView from './StatusView'
import Typography from '@material-ui/core/Typography'
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import './App.css';
import Amplify, { Auth } from 'aws-amplify'
import cx from 'clsx';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Rajdhani, sans-serif'
  }
})
const queryClient = new QueryClient()
Amplify.configure({
  Auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_ogUh1hd0j',
    identityPoolId: 'us-east-2:927b3edc-8c7a-49a8-a217-f72140d58ef1',
    userPoolWebClientId: '4tj9mq9s41lmbjv2emqmceb42h'
  },
  API: {
    endpoints: [
        {
            region: 'us-east-2',
            name: "McServer",
            endpoint: "https://pe27sezdwi.execute-api.us-east-2.amazonaws.com/dev"
        }
    ]
  }
});

const useStyles = makeStyles((theme) => {
  return {
    header2: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px'
      }
    },
    header3: {
      
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px'
      }
    },
    rootCode: {
      
      [theme.breakpoints.down('sm')]: {
        padding: '0px 16px',

      }
    }
  }
})
const checkLocalStore = (): boolean => {
  if (typeof window !== 'undefined') {
    const { ...items } = window.localStorage;
    const keys = Object.keys(items);
    if (keys.length === 0) return false;

    const foundAuth = keys.find(key => key.includes('CognitoIdendityServiceProvider'));
    return Boolean(foundAuth);
  }
  return false;
};


const checkAuthentication = async (setAuth: (authenticated: boolean | null) => void): Promise<void> => {
  if (checkLocalStore()) {
    setAuth(true);
  }

  try {
    await Auth.currentAuthenticatedUser();
    setAuth(true);
  } catch (e) {
    setAuth(false);
  }
};

async function signOut() {
  try {
      await Auth.signOut();
  } catch (error) {
      console.log('error signing out: ', error);
  }
}

const coolStuff = [
  "brandon",
  "culture",
  "fashion",
  "brandon",
  "machinelearning",
  "diversity",
  "synergy",
  "vision",
  "brandon",
  "democracy",
  "blockchain",
  "empowerment",
  "ai",
  "bodypositivity",
  "inclusion",
  "brandon",
  "design",
  "freespeech",
  "veganism",
  "magic"
]
const coolWords = [
  "groundbreaking",
  "first",
  "iconic",
  "rockstar",
  "visionary",
  "only",
  "tasty",
  "essential",
  "innovative",
  "original",
  "beautiful"
]
function App() {
  const [authenticated, setAuth] = React.useState<boolean | null>(null)
  const [resetNeeded, setReset] = React.useState<boolean | null>(null)
  const classes = useStyles()
  React.useEffect(() => {
    if(authenticated === null) {
      const initAuth = async () => {
        await checkAuthentication(setAuth)
      }
      initAuth()
    }

  }, [authenticated, setAuth])

  const handleSignOut = React.useCallback(async () => {
    await signOut()
    setAuth(null)
  }, [setAuth])

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
            {authenticated && <div className="Signout-Container">
              <Button variant="contained" onClick={handleSignOut}>Sign Out</Button>
              </div>}
            <header className="App-header">
              <Typography variant="h2" className={classes.header2}><b>BaD NeWs BrAnDoN</b></Typography>
              {!authenticated && 
              (<code className={cx(classes.header3, classes.rootCode)}>the {" "}
                <TextLoop> 
                  {coolWords.map((stuff, i) => <code key={`words-${i}`} className={classes.header3}>{stuff}</code>)}
                </TextLoop> gaming server powered by{" #"}
                <TextLoop>
                  {coolStuff.map((stuff, i) => <code key={i} className={classes.header3}>{stuff}</code>)}
                </TextLoop>.
              </code>)}
              
              {authenticated === null && <CircularProgress />}
              {authenticated === false && (<>
                
                <Login setAuth={setAuth} setReset={setReset}/>
                </>) }
                {authenticated && !resetNeeded && (
                  <StatusView />
                )}
                {authenticated && resetNeeded && (
                  <ResetPassword user={authenticated} setReset={setReset}/>
                )}
            </header>
        </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
