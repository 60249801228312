import React from 'react';
import { Typography, Button, Link, List, ListItem, CircularProgress, makeStyles, Tabs, Tab, Container } from '@material-ui/core'

import { API } from 'aws-amplify'
import {
    useQuery
  } from 'react-query'

const useStyles = makeStyles(() => {
    return {
        ip: {
            fontSize: '16px'
        },
        whitelist: {
            margin: '16px'
        },
        listItem: {
            whiteSpace: 'pre-wrap'
        },
        list: {
            fontSize: '16px'
        },
        tabContent: {
            marginTop: '32px'
        },
        link: {
            color: 'white',
            textDecoration: 'underline'
        },
        video: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        },
        videoContainer: {
            margin: '0 auto',
            position: 'relative',
            width: '100%',
            maxWidth: '620px',
            height: 0,
            paddingBottom: '56.25%'
          },

    }
})

function TabPanel(props: any) {
    const { children, value, index, className, ...other } = props;
  
    return (
      <div
        className={className}
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (

            children
        )}
      </div>
    );
  }

export function StatusView() {
    const classes = useStyles()
    const [status, setStatus] = React.useState();
    const [host, setHost] = React.useState();
    const [value, setValue] = React.useState(0);
    const { refetch: whitelist, isLoading: whiteListIsLoading, data: whitelistData } = useQuery<{ status?: "ok"}>("whitelist", async () => {
        return API.get('McServer', '/server/whitelist', {})
    }, {
        refetchOnWindowFocus: false,
        enabled: false // turned off by default, manual refetch is needed
      });

    const { data, isLoading, refetch: refetchStatus } = useQuery('statusInfo', async () => {
        return API.get('McServer', '/server/status', { });
    }, {
        ...(status !== 'stopped' && (status && !host ? {refetchInterval: 3000} : {refetchInterval: 20000}))
    })
    const s = data?.status
    const h = data?.serverInfo?.host
    React.useEffect(() => {
        if(s) {
            setStatus(s)
        }
    }, [s])
    React.useEffect(() => {
        if(h) {
            setHost(h)
        }
    }, [h])
    const { refetch: start, isLoading: startIsLoading, data: startData } = useQuery<{ status?: "ok"}>("whitelist", async () => {
        return API.get('McServer', '/server/start', {})
    }, {
        refetchOnWindowFocus: false,
        enabled: false // turned off by default, manual refetch is needed
    });

    const handleWhitelist = async () => {
        await whitelist();
        await refetchStatus();
    }
    const handleStart = async () => {
        await start()
        await refetchStatus();
    }
    const loading = isLoading || whiteListIsLoading || startIsLoading
    if(loading) {
        return <CircularProgress />
    }

    if(data?.ipOk === false && !whitelistData?.status) {
        return (<div>
            <Typography>Allow my IP to connect to the server:</Typography>
            <div>
                <code className={classes.ip}>{`Your IP: ${data?.playerIp}`}</code>
            </div>
            <Button variant="contained" className={classes.whitelist} onClick={handleWhitelist} disabled={loading}>Whitelist IP</Button>
            <Typography variant="subtitle2">(You can't play until you click this button.)</Typography>
        </div>)
        
    }


    const handleChange = (_: any, newValue: number) => {
      setValue(newValue);
    };


    return (
        <div>
            <code>Currently running ModPack:</code>
            <Typography><Link className={classes.link} href="https://www.feed-the-beast.com/modpack/ftb_revelation">Feed the Beast: Revelation version 3.4</Link></Typography>
            <Typography>Server address: mc.badnewsbrandon.com</Typography>
            <Tabs       
                value={value}
                onChange={handleChange}>
                <Tab label={"Status"} />
                <Tab label={"To Play"} />
                <Tab label={"Server Rules"} />
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabContent}>
                {data && <Typography>{`Server is currently: ${data.status}`}</Typography> }
                {data.status === 'stopped' && !loading &&  <Button variant="contained" disabled={Boolean(startData)} onClick={handleStart}>Start Server</Button>}
                {!data.serverInfo?.host && data.status !== 'stopped' && data.status !== 'stopping' && <Typography>This will take a few minutes...</Typography>}
                {data.serverInfo?.host && <div>
                    {data.serverInfo?.onlinePlayers === null && <Typography>Minecraft Starting...</Typography>}
                    {data.serverInfo?.onlinePlayers !== null && <Typography>{`Minecraft Running version ${data.serverInfo.version}`}</Typography>}
                    {data.serverInfo?.onlinePlayers !== null && <Typography>{`Players Online: ${data.serverInfo?.onlinePlayers} / ${data.serverInfo?.maxPlayers}`}</Typography>}
                </div>}
            </TabPanel>
            <TabPanel value={value} index={1} >
                <List className={classes.list}>
                    <ListItem>Click Start Server if it's stopped.</ListItem>
                    <ListItem className={classes.listItem}>
                        Modpack requires the latest version of <Link href={'https://www.oracle.com/java/technologies/javase/javase-jdk8-downloads.html'}>Java 8</Link>
                    </ListItem>
                    <ListItem> <Link href="https://curseforge.overwolf.com/"> Download and Install CurseForge</Link></ListItem>
                    <ListItem>Go to Minecraft and then browse modpacks</ListItem>
                    <ListItem>Search FTB Revelation</ListItem>
                    <ListItem>Click Versions and  then download 3.4</ListItem>
                    <ListItem>Click Play in CurseForge</ListItem>
                </List>
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabContent}>
                <Container disableGutters className={classes.videoContainer}>
                    <iframe
                        className={classes.video}
                        title={"reuls"}
                        src="https://www.youtube.com/embed/v2TRFIVZQEI"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </Container>
            </TabPanel>

    
        </div>)
}

export default StatusView