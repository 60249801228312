import React from 'react';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'clsx'
import { Auth } from 'aws-amplify'
const useStyles = makeStyles(() => {
    return {
        loginContainer: {
            padding: '24px'
        },
        loginText: {
            fontSize: '32px',

        },
        textField: {
            backgroundColor: 'white',
            borderRadius: '4px',
            margin: '8px'
        }
    }
})

function useSignIn(): [(username: string, password: string) => void, any, any, boolean] {
    const [user, setUser] = React.useState()
    const [error, setError] = React.useState()
    const [loading, setLoading] = React.useState(false)
    return [async  (username: string, password: string) => {
        try {
            setLoading(true)
            const usr = await Auth.signIn(username, password);
            setUser(usr)
            setLoading(false)
            setError(undefined)
        } catch (error) {
            setLoading(false)
            console.log('error signing in', error);
            setError(error)

        }
    }, user, error, loading]
}
function Login({setAuth, setReset}: {setAuth: (auth: any) => void, setReset: (auth: boolean | null) => void}) {
    const classes = useStyles()
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [signIn, user, error, loading] = useSignIn()

    const handleUsername = React.useCallback((e) => {

        setUsername(e.target.value)
    }, [setUsername])
    const handlePassword = React.useCallback((e) => {
        setPassword(e.target.value)
    }, [setPassword])

    const handleSubmit = React.useCallback(async (e) => {
        e.preventDefault()
        await signIn(username, password)
    }, [username, password, signIn])

    React.useEffect(() => {
        if(user) {
            setAuth(user)
            if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setReset(true)
            }
        }
    }, [user, setAuth, setReset])
    return (
    <form onSubmit={handleSubmit}>
        <div className={classes.loginContainer}>
            <code className={classes.loginText}>Login</code>
        </div>
        {error && <div><code style={{ color: 'red' }}>Something went wrong!</code></div>}
        <TextField 
            className={cx(classes.textField)} 
            variant="outlined" 
            placeholder="Username"
            onChange={handleUsername}
            />
        <br />
        <TextField 
            className={cx(classes.textField)} 
            variant="outlined" 
            placeholder="Password" 
            type="password" 
            onChange={handlePassword}
            />
        <br/>
        <Button variant="contained" size="large" type="submit" disabled={loading}>Login</Button>
    </form>)
}

export default Login